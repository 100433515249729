import React, {Suspense, lazy} from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const HomePage = lazy(() => import('./pages/NewHome'));
const SalesPage = lazy(() => import('./pages/Home'));
const TeamPage = lazy(() => import('./pages/Team'));
const TemplatesPage = lazy(() => import('./pages/Templates'));
const VenturesPage = lazy(() => import('./pages/Subdivisions'));

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Portfolio = lazy(() => import('./pages/Projects'));
const Services = lazy(() => import('./pages/Services'));
const About = lazy(() => import('./pages/Origin'));
const Thesis = lazy(() => import('./pages/Thesis'));
const PrivacyPolicyPage = lazy(() => import('./pages/Privacy'));
const TermsAndConditionsPage = lazy(() => import('./pages/Terms'));

const LoadingLogo = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white">
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className="flex items-center space-x-3"
    >
      <motion.div 
        className="w-10 h-10 bg-[#26A4FF] rounded-full flex items-center justify-center"
        animate={{ 
          scale: [1, 1.2, 1]
        }}
        transition={{ 
          scale: { duration: 1, repeat: Infinity }
        }}
      >
        <img
          src="/favicon.png"
          alt="SZN Logo"
          width={40}
          height={40}
        />
      </motion.div>
      <span className="text-5xl font-light tracking-tight text-gray-900">
        <span className="font-semibold">SZN</span>LABS
      </span>
    </motion.div>
  </div>
);

const App: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      <Suspense fallback={<LoadingLogo />}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/ventures" element={<VenturesPage />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/thesis" element={<Thesis />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </div>
  );
};

export default App;
