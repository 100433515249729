import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
// create state with Zustand
/// import { create } from 'zustand'
/// import { devtools, persist } from 'zustand/middleware'
/// import type {} from '@redux-devtools/extension' 
// use Remix React Router: Routes, Link, useLocation
import { BrowserRouter } from 'react-router-dom'; 
// import standalone css
import './index.css';

const rootElement = document.getElementById('root')! as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
